import { projects } from './data/projectsArray'
import ProjectCard from './ProjectCard'

export default function Projects() {
  return (
    <div className='projects_container'>
      {projects.toReversed().map((project) => (
        <ProjectCard key={project.id} name={project.name} heroAlt={project.heroAlt} sourceSet={project.sourceSet} code={project.code}/>
      ))}
    </div>
  )
}
