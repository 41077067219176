import Header from './components/Header'
import Projects from './components/Projects'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Highlight from './components/Highlight'
import { useState } from 'react'

function App() {

  const [showNav, setShowNav] = useState(false);

  const navbarClick = () => {
    setShowNav(!showNav);
    console.log(showNav)
  }

  return (
  <Router>
    <div className="App">
    <Header navbarClick={navbarClick} showNav={showNav} />
    <Routes>
      <Route
        path='/'
        element={
          <>
          
          <Projects />
          </>
        }
      />
      <Route path='/about' element={<><h2>Hello!</h2></>}/>
      <Route path='/highlight' element={<><Highlight /></>}/>
    </Routes>
    </div>
  </Router>
  );
}

export default App;
