//New Balance
//#region
export const newbalance_hero_800 = require('./img/2023/newbalance-23/newbalance-hero-800.jpg')
export const newbalance_hero_1920 = require('./img/2023/newbalance-23/newbalance-hero-1920.jpg')
export const newbalance_hero_3840 = require('./img/2023/newbalance-23/newbalance-hero-3840.jpg')

export const newbalance_1_800 = require('./img/2023/newbalance-23/newbalance-1-800.jpg')
export const newbalance_1_1920 = require('./img/2023/newbalance-23/newbalance-1-1920.jpg')
export const newbalance_1_3840 = require('./img/2023/newbalance-23/newbalance-1-3840.jpg')

export const newbalance_2_800 = require('./img/2023/newbalance-23/newbalance-2-800.jpg')
export const newbalance_2_1920 = require('./img/2023/newbalance-23/newbalance-2-1920.jpg')
export const newbalance_2_3840 = require('./img/2023/newbalance-23/newbalance-2-3840.jpg')

export const newbalance_3_800 = require('./img/2023/newbalance-23/newbalance-3-800.jpg')
export const newbalance_3_1920 = require('./img/2023/newbalance-23/newbalance-3-1920.jpg')
export const newbalance_3_3840 = require('./img/2023/newbalance-23/newbalance-3-3840.jpg')
//#endregion


//Sandaime Richard
//#region
export const richard_hero_800 = require('./img/2023/richard-23/richard-hero-800.jpg')
export const richard_hero_1920 = require('./img/2023/richard-23/richard-hero-1920.jpg')
export const richard_hero_3840 = require('./img/2023/richard-23/richard-hero-3840.jpg')

export const richard_1_800 = require('./img/2023/richard-23/richard-1-800.jpg')
export const richard_1_1920 = require('./img/2023/richard-23/richard-1-1920.jpg')
export const richard_1_3840 = require('./img/2023/richard-23/richard-1-3840.jpg')

export const richard_2_800 = require('./img/2023/richard-23/richard-2-800.jpg')
export const richard_2_1920 = require('./img/2023/richard-23/richard-2-1920.jpg')
export const richard_2_3840 = require('./img/2023/richard-23/richard-2-3840.jpg')

export const richard_3_800 = require('./img/2023/richard-23/richard-3-800.jpg')
export const richard_3_1920 = require('./img/2023/richard-23/richard-3-1920.jpg')
export const richard_3_3840 = require('./img/2023/richard-23/richard-3-3840.jpg')

export const richard_4_800 = require('./img/2023/richard-23/richard-4-800.jpg')
export const richard_4_1920 = require('./img/2023/richard-23/richard-4-1920.jpg')
export const richard_4_3840 = require('./img/2023/richard-23/richard-4-3840.jpg')

export const richard_5_800 = require('./img/2023/richard-23/richard-5-800.jpg')
export const richard_5_1920 = require('./img/2023/richard-23/richard-5-1920.jpg')
export const richard_5_3840 = require('./img/2023/richard-23/richard-5-3840.jpg')
//#endregion

//Attempted Rape of Susanna
//#region
export const susanna_hero_800 = require('./img/2023/susanna-23/susanna-hero-800.jpg')
export const susanna_hero_1920 = require('./img/2023/susanna-23/susanna-hero-1920.jpg')
export const susanna_hero_3840 = require('./img/2023/susanna-23/susanna-hero-3840.jpg')

export const susanna_1_800 = require('./img/2023/susanna-23/susanna-1-800.jpg')
export const susanna_1_1920 = require('./img/2023/susanna-23/susanna-1-1920.jpg')
export const susanna_1_3840 = require('./img/2023/susanna-23/susanna-1-3840.jpg')

export const susanna_2_800 = require('./img/2023/susanna-23/susanna-2-800.jpg')
export const susanna_2_1920 = require('./img/2023/susanna-23/susanna-2-1920.jpg')
export const susanna_2_3840 = require('./img/2023/susanna-23/susanna-2-3840.jpg')

export const susanna_3_800 = require('./img/2023/susanna-23/susanna-3-800.jpg')
export const susanna_3_1920 = require('./img/2023/susanna-23/susanna-3-1920.jpg')
export const susanna_3_3840 = require('./img/2023/susanna-23/susanna-3-3840.jpg')
//#endregion

//Abigial Williams
//#region
export const abigail_hero_800 = require('./img/2023/abigail-23/abigail-hero-800.jpg')
export const abigail_hero_1920 = require('./img/2023/abigail-23/abigail-hero-1920.jpg')
export const abigail_hero_3840 = require('./img/2023/abigail-23/abigail-hero-3840.jpg')

export const abigail_1_800 = require('./img/2023/abigail-23/abigail-1-800.jpg')
export const abigail_1_1920 = require('./img/2023/abigail-23/abigail-1-1920.jpg')
export const abigail_1_3840 = require('./img/2023/abigail-23/abigail-1-3840.jpg')

export const abigail_2_800 = require('./img/2023/abigail-23/abigail-2-800.jpg')
export const abigail_2_1920 = require('./img/2023/abigail-23/abigail-2-1920.jpg')
export const abigail_2_3840 = require('./img/2023/abigail-23/abigail-2-3840.jpg')

export const abigail_3_800 = require('./img/2023/abigail-23/abigail-3-800.jpg')
export const abigail_3_1920 = require('./img/2023/abigail-23/abigail-3-1920.jpg')
export const abigail_3_3840 = require('./img/2023/abigail-23/abigail-3-3840.jpg')

export const abigail_4_800 = require('./img/2023/abigail-23/abigail-4-800.jpg')
export const abigail_4_1920 = require('./img/2023/abigail-23/abigail-4-1920.jpg')
export const abigail_4_3840 = require('./img/2023/abigail-23/abigail-4-3840.jpg')

export const abigail_5_800 = require('./img/2023/abigail-23/abigail-5-800.jpg')
export const abigail_5_1920 = require('./img/2023/abigail-23/abigail-5-1920.jpg')
export const abigail_5_3840 = require('./img/2023/abigail-23/abigail-5-3840.jpg')

export const abigail_6_800 = require('./img/2023/abigail-23/abigail-6-800.jpg')
export const abigail_6_1920 = require('./img/2023/abigail-23/abigail-6-1920.jpg')
export const abigail_6_3840 = require('./img/2023/abigail-23/abigail-6-3840.jpg')

export const abigail_7_800 = require('./img/2023/abigail-23/abigail-7-800.jpg')
export const abigail_7_1920 = require('./img/2023/abigail-23/abigail-7-1920.jpg')
export const abigail_7_3840 = require('./img/2023/abigail-23/abigail-7-3840.jpg')

export const abigail_8_800 = require('./img/2023/abigail-23/abigail-8-800.jpg')
export const abigail_8_1920 = require('./img/2023/abigail-23/abigail-8-1920.jpg')
export const abigail_8_3840 = require('./img/2023/abigail-23/abigail-8-3840.jpg')
//#endregion

//Seen
//#region
export const seen_hero_800 = require('./img/2023/seen-23/seen-hero-800.jpg')
export const seen_hero_1920 = require('./img/2023/seen-23/seen-hero-1920.jpg')
export const seen_hero_3840 = require('./img/2023/seen-23/seen-hero-3840.jpg')

export const seen_1_800 = require('./img/2023/seen-23/seen-1-800.jpg')
export const seen_1_1920 = require('./img/2023/seen-23/seen-1-1920.jpg')
export const seen_1_3840 = require('./img/2023/seen-23/seen-1-3840.jpg')

export const seen_2_800 = require('./img/2023/seen-23/seen-2-800.jpg')
export const seen_2_1920 = require('./img/2023/seen-23/seen-2-1920.jpg')
export const seen_2_3840 = require('./img/2023/seen-23/seen-2-3840.jpg')

export const seen_3_800 = require('./img/2023/seen-23/seen-3-800.jpg')
export const seen_3_1920 = require('./img/2023/seen-23/seen-3-1920.jpg')
export const seen_3_3840 = require('./img/2023/seen-23/seen-3-3840.jpg')

export const seen_4_800 = require('./img/2023/seen-23/seen-4-800.jpg')
export const seen_4_1920 = require('./img/2023/seen-23/seen-4-1920.jpg')
export const seen_4_3840 = require('./img/2023/seen-23/seen-4-3840.jpg')

export const seen_5_800 = require('./img/2023/seen-23/seen-5-800.jpg')
export const seen_5_1920 = require('./img/2023/seen-23/seen-5-1920.jpg')
export const seen_5_3840 = require('./img/2023/seen-23/seen-5-3840.jpg')

export const seen_6_800 = require('./img/2023/seen-23/seen-6-800.jpg')
export const seen_6_1920 = require('./img/2023/seen-23/seen-6-1920.jpg')
export const seen_6_3840 = require('./img/2023/seen-23/seen-6-3840.jpg')
//#endregion

//Venus in Fur
//#region
export const venusinfur_hero_800 = require('./img/2023/venusinfur-23/venusinfur-hero-800.jpg')
export const venusinfur_hero_1920 = require('./img/2023/venusinfur-23/venusinfur-hero-1920.jpg')
export const venusinfur_hero_3840 = require('./img/2023/venusinfur-23/venusinfur-hero-3840.jpg')

export const venusinfur_1_800 = require('./img/2023/venusinfur-23/venusinfur-1-800.jpg')
export const venusinfur_1_1920 = require('./img/2023/venusinfur-23/venusinfur-1-1920.jpg')
export const venusinfur_1_3840 = require('./img/2023/venusinfur-23/venusinfur-1-3840.jpg')

export const venusinfur_2_800 = require('./img/2023/venusinfur-23/venusinfur-2-800.jpg')
export const venusinfur_2_1920 = require('./img/2023/venusinfur-23/venusinfur-2-1920.jpg')
export const venusinfur_2_3840 = require('./img/2023/venusinfur-23/venusinfur-2-3840.jpg')

export const venusinfur_3_800 = require('./img/2023/venusinfur-23/venusinfur-3-800.jpg')
export const venusinfur_3_1920 = require('./img/2023/venusinfur-23/venusinfur-3-1920.jpg')
export const venusinfur_3_3840 = require('./img/2023/venusinfur-23/venusinfur-3-3840.jpg')

export const venusinfur_4_800 = require('./img/2023/venusinfur-23/venusinfur-4-800.jpg')
export const venusinfur_4_1920 = require('./img/2023/venusinfur-23/venusinfur-4-1920.jpg')
export const venusinfur_4_3840 = require('./img/2023/venusinfur-23/venusinfur-4-3840.jpg')
//#endregion