import { useSearchParams } from 'react-router-dom'
import { projects } from './data/projectsArray'
import HighlightImageHandler from './HighlightImageHandler'
import { Link } from 'react-router-dom'
import ResetScroll from './ResetScroll'

export default function Highlight() {

    const [searchParams] = useSearchParams();
    const parameter = searchParams.get('show')

    const showData = projects.filter(project => {
        return project.code === parameter
    });

    const heroImage = showData[0].sourceSet
    const images = showData[0].images
    const name = showData[0].name
    const meta = showData[0].meta
    const heroAlt = showData[0].heroAlt

  return (
  <div className='highlight-container container'>
      <div className='highlight-hero'>
        <div className='imgOverlay'></div>
        <h2 className='heroText'>{name}</h2>
        <div className='hero-image-container'><img srcSet={heroImage} alt={heroAlt} /></div>
      </div>

      <div className='highlight-meta'>{meta}</div>
      <div className='showcase'>
        {images.map(image => (<HighlightImageHandler key={image.key} altText={image.alt} src={image.sourceset} photographer={image.photographer} gridcols={image.gridcols} gridrows={image.gridrows}/>))}
    </div>
    <div className='grid-col-span-3 return-link-container'><Link onClick={ResetScroll} className='return-home-link' to='/'>Return to Projects</Link></div>
  </div>
  )
}
