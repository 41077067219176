import { Link } from 'react-router-dom'
import ResetScroll from './ResetScroll'

export default function ProjectCard({ name, sourceSet, code, heroAlt }) {
  return (
    <Link onClick={ResetScroll} to={`/highlight?show=${code}`}>
    <div className="project_card_container">
      <div className='hero-image-container'>
        <div className='project-card-overlay'></div>
        <div className='project-card-text'><h2>{name}</h2></div>
        <img className='hero-image' srcSet={sourceSet} alt={heroAlt} />
      </div>
    </div>
    </Link>    
  )
}
