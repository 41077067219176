import { Link } from 'react-router-dom'
import ResetScroll from './ResetScroll'
// import burger from './data/svg/burger.svg'

export default function Header({ navbarClick, showNav }) {
  return (
    <div className="header-container">
    <Link onClick={ResetScroll} to='/'>
      <h1><span id='header-first-name'>Chris</span> <span id='header-last-name'>Milburn</span></h1>
    </Link>

      {/* <div className="nav-container">
        <button className='show-hide-btn' onClick={navbarClick}><img className='burger' src={burger}></img></button>
        <nav>
          {(showNav === true) && 
          <ul className="nav-items">
            <li>Projects</li>
          </ul>
          }
        </nav>
        
      </div> */}
    </div>
  )
}