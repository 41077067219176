 import * as i from './imageImporter'
 
 //template
//  {
//     id: 6,
//     code: 'seen',
//     name: 'Seen',
//     heroAlt: '',
//     sourceSet: ``,
//     images: [
//         {key: 1, sourceset: ``, gridcols: '2', gridrows: '1', alt: ''},
//     ],
//     meta: '',
// },

 export const projects = [
    {
        id: 1,
        code: 'newbalance',
        name: 'New Balance',
        sourceSet: `${i.newbalance_hero_800} 800w, ${i.newbalance_hero_1920} 1920w,${i.newbalance_hero_3840} 3840w`,
        heroAlt: 'Christopher Bryant (actor) walks balancing, lit by yellow and green light',
        images: [
            {key: 1, sourceset: `${i.newbalance_1_800} 800w, ${i.newbalance_1_1920} 1920w,${i.newbalance_1_3840} 3840w`, alt: 'A midshot of actor Christopher Bryant sitting with text projected behind him that reads cos I sucked his dick here', gridcols: '2', gridrows: '1'},
            {key: 2, sourceset: `${i.newbalance_2_800} 800w, ${i.newbalance_2_1920} 1920w,${i.newbalance_2_3840} 3840w`, alt: 'A wide shot of actor Christopher Bryant on the floor with text projected behind him', gridcols: '2', gridrows: '1'},
            {key: 3, sourceset: `${i.newbalance_3_800} 800w, ${i.newbalance_3_1920} 1920w,${i.newbalance_3_3840} 3840w`, alt: 'Christopher Bryant (actor) stands in front of a wall of text', gridcols: '1', gridrows: '2'},
        ],
        meta: 'Director: Emma Palackic\nWriter and Performer: Christopher Bryant\nLighting Design: Chris Milburn\nProjection Design: Justin Gardam\n\nProduction Photos: Robert Catto',
    },
    {
        id: 2,
        code: 'venusinfur',
        name: 'Venus in Fur',
        heroAlt: '',
        sourceSet: `${i.venusinfur_hero_800} 800w, ${i.venusinfur_hero_1920} 1920w,${i.venusinfur_hero_3840} 3840w`,
        images: [
            {key: 1, sourceset: `${i.venusinfur_1_800} 800w, ${i.venusinfur_1_1920} 1920w,${i.venusinfur_1_3840} 3840w`, gridcols: '2', gridrows: '1', alt: ''},
            {key: 2, sourceset: `${i.venusinfur_2_800} 800w, ${i.venusinfur_2_1920} 1920w,${i.venusinfur_2_3840} 3840w`, gridcols: '2', gridrows: '1', alt: ''},
            {key: 3, sourceset: `${i.venusinfur_3_800} 800w, ${i.venusinfur_3_1920} 1920w,${i.venusinfur_3_3840} 3840w`, gridcols: '2', gridrows: '1', alt: ''},
            {key: 4, sourceset: `${i.venusinfur_4_800} 800w, ${i.venusinfur_4_1920} 1920w,${i.venusinfur_4_3840} 3840w`, gridcols: '1', gridrows: '2', alt: ''},
        ],
        meta: 'Director: Ryan Whitworth\nLighting Design: Chris Milburn\nSet and Costume Design: Paris Burrows\nSound Design: Madeleine Picard\n\nProduction Photos: Paris Burrows',
    },
    {
        id: 3,
        code: 'sandaimerichard',
        name: 'Sandaime Richard',
        sourceSet: `${i.richard_hero_800} 800w, ${i.richard_hero_1920} 1920w,${i.richard_hero_3840} 3840w`,
        heroAlt: '',
        images: [
            {key: 1, sourceset: `${i.richard_1_800} 800w, ${i.richard_1_1920} 1920w,${i.richard_1_3840} 3840w`, gridcols: '2', gridrows: '1', alt: 'A wide shot of a man atop a ladder with a blue background'},
            {key: 2, sourceset: `${i.richard_2_800} 800w, ${i.richard_2_1920} 1920w,${i.richard_2_3840} 3840w`,gridcols: '2', gridrows: '1', alt: '3 people stand in a sand pit in front of a white backrop'},
            {key: 3, sourceset: `${i.richard_3_800} 800w, ${i.richard_3_1920} 1920w,${i.richard_3_3840} 3840w`, gridcols: '1', gridrows: '2', alt: 'A person wearing a large red coat looks stunned, standing in front of a red backdrop'},
            {key: 5, sourceset: `${i.richard_5_800} 800w, ${i.richard_5_1920} 1920w,${i.richard_5_3840} 3840w`, gridcols: '1', gridrows: '2', alt: 'The shadow of a person with their arms raised'},
            {key: 4, sourceset: `${i.richard_4_800} 800w, ${i.richard_4_1920} 1920w,${i.richard_4_3840} 3840w`, gridcols: '2', gridrows: '1', alt: 'wide shot of a person walking through a sandpit in front of a pink backdrop'},
        ],
        meta: 'Director: Ong Keng Sen\nLighting Design: Chris Milburn\nSet Design: James Stiblj\nCostume Design: Paris Burrows\nSound Design: Keelan Ellis\n\nProduction Photos: Phil Erbacher',
    },
    {
        id: 4,
        code: 'susanna',
        name: 'The Attempted Rape of Susanna',
        heroAlt: 'Seven people stand on stage. A distressed looking person stands in the foreground with a golf club',
        sourceSet: `${i.susanna_hero_800} 800w, ${i.susanna_hero_1920} 1920w,${i.susanna_hero_3840} 3840w`,
        images: [
            {key: 1, sourceset: `${i.susanna_1_800} 800w, ${i.susanna_1_1920} 1920w,${i.susanna_1_3840} 3840w`, gridcols: '2', gridrows: '1', alt: 'Four people sit on a couch and a person kneels to be used as a footrest.'},
            {key: 2, sourceset: `${i.susanna_2_800} 800w, ${i.susanna_2_1920} 1920w,${i.susanna_2_3840} 3840w`, gridcols: '2', gridrows: '1', alt: 'A person stands kicking and punching whilst wearing boxing gloves'},
            {key: 3, sourceset: `${i.susanna_3_800} 800w, ${i.susanna_3_1920} 1920w,${i.susanna_3_3840} 3840w`, gridcols: '1', gridrows: '2', alt: 'A person bathed in blue light looks defeated'},
        ],
        meta: 'Writer and Director: Rosa Campagnaro\nComposer: Sally Whitwell\nLighting Design: Chris Milburn\nSet and Costume Design: Paris Koppens\n\nProduction Photos: Phil Erbacher',
    },
    {
        id: 5,
        code: 'abigailwilliams',
        name: 'Abigail Williams',
        heroAlt: '',
        sourceSet: `${i.abigail_hero_800} 800w, ${i.abigail_hero_1920} 1920w,${i.abigail_hero_3840} 3840w`,
        images: [
            {key: 8, sourceset: `${i.abigail_8_800} 800w, ${i.abigail_8_1920} 1920w,${i.abigail_8_3840} 3840w`, gridcols: '1', gridrows: '2', alt: ''},
            {key: 1, sourceset: `${i.abigail_1_800} 800w, ${i.abigail_1_1920} 1920w,${i.abigail_1_3840} 3840w`, gridcols: '2', gridrows: '1', alt: ''},
            {key: 2, sourceset: `${i.abigail_2_800} 800w, ${i.abigail_2_1920} 1920w,${i.abigail_2_3840} 3840w`, gridcols: '2', gridrows: '1', alt: ''},
            {key: 3, sourceset: `${i.abigail_3_800} 800w, ${i.abigail_3_1920} 1920w,${i.abigail_3_3840} 3840w`, gridcols: '2', gridrows: '1', alt: ''},
            {key: 7, sourceset: `${i.abigail_7_800} 800w, ${i.abigail_7_1920} 1920w,${i.abigail_7_3840} 3840w`, gridcols: '1', gridrows: '2', alt: ''},
            {key: 4, sourceset: `${i.abigail_4_800} 800w, ${i.abigail_4_1920} 1920w,${i.abigail_4_3840} 3840w`, gridcols: '2', gridrows: '1', alt: ''},
            {key: 5, sourceset: `${i.abigail_5_800} 800w, ${i.abigail_5_1920} 1920w,${i.abigail_5_3840} 3840w`, gridcols: '2', gridrows: '1', alt: ''},
            {key: 6, sourceset: `${i.abigail_6_800} 800w, ${i.abigail_6_1920} 1920w,${i.abigail_6_3840} 3840w`, gridcols: '1', gridrows: '2', alt: ''},
            
        ],
        meta: 'Writer and Director: Rebecca McNamee\nLighting Design: Chris Milburn\nSound Design: Keelan Ellis\nSet and Costume Design: Angelina Daniel\n\nProduction Photos: Phil Erbacher',
    },
    {
        id: 6,
        code: 'seen',
        name: 'Seen',
        heroAlt: '',
        sourceSet: `${i.seen_hero_800} 800w, ${i.seen_hero_1920} 1920w,${i.seen_hero_3840} 3840w`,
        images: [
            {key: 1, sourceset: `${i.seen_1_800} 800w, ${i.seen_1_1920} 1920w,${i.seen_1_3840} 3840w`, gridcols: '1', gridrows: '2', alt: ''},
            {key: 2, sourceset: `${i.seen_2_800} 800w, ${i.seen_2_1920} 1920w,${i.seen_2_3840} 3840w`, gridcols: '2', gridrows: '1', alt: ''},
            {key: 3, sourceset: `${i.seen_3_800} 800w, ${i.seen_3_1920} 1920w,${i.seen_3_3840} 3840w`, gridcols: '2', gridrows: '1', alt: ''},
            {key: 4, sourceset: `${i.seen_4_800} 800w, ${i.seen_4_1920} 1920w,${i.seen_4_3840} 3840w`, gridcols: '2', gridrows: '1', alt: ''},
            {key: 5, sourceset: `${i.seen_5_800} 800w, ${i.seen_5_1920} 1920w,${i.seen_5_3840} 3840w`, gridcols: '2', gridrows: '1', alt: ''},
            {key: 6, sourceset: `${i.seen_6_800} 800w, ${i.seen_6_1920} 1920w,${i.seen_6_3840} 3840w`, gridcols: '1', gridrows: '2', alt: ''},
        ],
        meta: 'Director: Jo Bradley\nWriter: Xavier Hazard\nLighting Design: Chris Milburn\nAssoc. Lighting Design: Harry Smyth\nSet and Costume Design: Elle Fitzgerald\nVideo Design: TK Abioye\nSound Design: Carol Gonzales\n\nProduction Photos: Phil Erbacher',
    },
]

